body{
  background-image: url('./images/AlasBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; 
  background-position: center;
  /* background-size: contain; */
  font-size: 20px;
  font-family: Helvetica, sans-serif;
}
h1{
  font-size: 2em;
}
p{
  font-size: 22px;
}
header{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
  font-size: 20px;
  
  background-color: #edc121;
  border-bottom: 15px double;
  /* border-bottom: 4mm ridge rgb(169, 167, 11); */
}
.tab{
  color: black;
  text-decoration: none;
  padding: 28px 0px 28px 0px;
  transition: .2s ease-in-out;
}
.tab:hover{
  background-color: black;
  color: white;
}

/* articles */
article{
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.523); 
  border-radius: 20px;
  width: 70%;
  margin: 0 auto;
  
}

/* events */

.Events{
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 200px;
  margin: 100px 0 100px 0;
}

.Event{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 70%;
  min-height: 350px;
  min-width: 200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f0dc;
  color: black;
  border-radius: 20px;
}
.EventImage{
  width: 100%;
  max-width: 300px;
}
.FilteredEvent{
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  width: 50%;
  min-width: 290px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f0dc;
  color: black;
  border-radius: 20px;
}
.FilteredEventLinks{
  display: flex;
  column-gap: 50px;
  justify-content: center;
}
.EventTitle{
  margin: 0;
}
.EventDescription{
  text-align: right;
}
.EventLinks{
  margin-top: 30px;
  display: flex;
  column-gap: 50px;
  justify-content: right;
}
.FilteredEventLinks{
  margin-top: 25px;
}
.SocialLinks{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 200px; 
}
a>img{
  transition: .2s ease-in-out;
  filter: brightness(.60);
}
a>img:hover{
  width: 200px;
  filter: brightness(1);
}
.EventLinks>a, .FilteredEventLinks>a{
  text-decoration: none;
  color: black;
  background-color: #edc121;;
  border-radius: 15px;
  padding: 10px 13px 10px 13px;
  font-size: 16px;
  text-align: center;
  transition: ease-in-out .2s;
}
.EventLinks>a:hover{
  background-color: #bb9603;
  color: white;
}
.FilteredEvents{
  display: grid;
  row-gap: 20px;
  margin: 20px 0 20px 0;
}
.Blogs{
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  width: 98%;
  margin: 0 auto;
}
.BlogDescription{
  width: 100%;
  text-align: left;
}
.Blog{
  display: grid;
  color: rgb(253, 253, 122);
  background-color: burlywood;
  padding: 20px;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  column-gap: 25px;
  text-decoration: none;
  color: black;
  border-radius: 20px;
  transition: ease-in-out .2s;
}
.FilteredBlog:hover, .Blog:hover, button[type=submit]:hover{
  filter: brightness(70%);
}
.BlogTitle{
  margin: 0;
}
form{
  width: 50%;
  min-width: 280px;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 10px;
  margin: 0 auto;
}
input[type=text], input[type=email]{
  height: 30px;
  font-size: 17px;
  border-radius: 10px;
}
button[type=submit]{
  background-color: #edc121;;
  width: 300px;
  font-size: 30px;
  border-radius: 10px;
  margin: 0 auto;
  transition: .2s ease-in-out;
}
.search{
  width: 300px;
}
textarea{
  height: 100px;
  resize: none;
  border-radius: 10px;
  font-size: 17px;
}
@media (min-width: 701px) {
  .Event{
    min-width: 600px;
  }
}
.FilteredBlog{
  display: grid;
  row-gap: 20px;
  background-color: burlywood;
  width: 40%;
  min-width: 300px;
  margin: 0 auto;
  padding: 20px;
  justify-items: center;
  text-decoration: none;
  color: black;
  transition: ease-in-out .2s;
}
.FilteredBlogs{
  margin: 20px 0 20px 0;
  display: grid;
  grid-template-rows: auto;
  row-gap: 20px;
}
.EboardMember{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 500px));
  align-items: center;
  padding: 25px 0;
}
.EboardMemberImage{
  margin: 0 auto;
  border-radius: 20px;
}
.EboardMemberDesc{
  font-size: 14px;
  text-align: left;
  padding:  0 20px;
}

@media (max-width: 900px){
  article{
    width: 90%;
  }
  .BlogDescription{
    text-align: center;
  }
  .Blog{
    grid-template-columns: none;
  }
  .Blogs{
    grid-template-columns: 1fr;
  }
}
@media (max-width: 700px) {
  header{
    font-size: 20px;
    grid-template-columns: 1fr 0fr 1fr 1fr 1fr 1fr;
  }
  .Event{
    width: 90%;
    display: block;
  }
  .EventDescription{
    text-align: center;
  }
  .EventLinks{
    justify-content: center;
  }
}

@media (max-width: 1307px) {
  body{
    background-image: url('./images/AlasBackgroundTablet.jpg');
  }
}

@media (max-width: 620px) {
  body{
    background-image: url('./images/AlasBackgroundMobile.png');
  }
}